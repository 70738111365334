import { useRouter } from "next/router";
import { useContext, useEffect, useState } from "react";
import ReactPixel from "react-facebook-pixel";
import { ConfigStateContext } from "../../Contexts/Configurations/context";
import { useWaitUsrInteraction } from "./useWaitUsrInteraction";

export const useFacebookPixel = (isInitializer:boolean = false) => {
	const router = useRouter();
	const { facebook_configuration, ...rest } = useContext(ConfigStateContext);
	const IS_BROWSER = typeof window !== "undefined";
	const { hasInteract } = useWaitUsrInteraction();

	const Init = () => {
		ReactPixel.init(facebook_configuration.pixel_id);
		window["FB_INITIALIZED"] = true;
		console.log("%cInit Facebook Pixel", "color:white; background: #1877f2; padding:2px 4px");
	};

	const PageViewEvent = () => {
		if (IS_BROWSER && window["FB_INITIALIZED"]) {
			ReactPixel.pageView();
			console.log(
				"%cFacebook Pixel PageView",
				"color:white; background: #1877f2; padding:2px 4px"
			);
		}
	};



	useEffect(() => {
		if (isInitializer && IS_BROWSER && !window["FB_INITIALIZED"] && facebook_configuration && hasInteract) {
				Init();
				PageViewEvent();
				// al cambiar de pagina con shallow routing que mande el evento de PageView
				const customHistory = [window.location.href];
				router.events.on("routeChangeComplete", (e, e2) => {
					if (customHistory[0] != window.location.href) {
						PageViewEvent();
						customHistory.unshift(window.location.href);
					}
				});
			// end al cambiar de pagina con shallow routing que mande el evento de PageView
		}
	}, [facebook_configuration, hasInteract]);

	return {
		// Standard Events : https://developers.facebook.com/docs/app-events/reference#web
		StandardEvent: (title = "", data = {}) => {
			if (IS_BROWSER && window["FB_INITIALIZED"] && title && Object.keys(data).length === 0) {
				ReactPixel.track(title, data);

				console.log(
					"%cFacebook Pixel StandardEvent",
					"color:white; background: #1094f4; padding:2px 4px"
				);
			}
		},

		// Custom Events
		CustomEvent: (title = "", data = {}) => {
			if (IS_BROWSER && window["FB_INITIALIZED"] && title && Object.keys(data).length === 0) {
				ReactPixel.trackCustom(title, data);

				console.log(
					"%cFacebook Pixel CustomEvent",
					"color:white; background: #1094f4; padding:2px 4px"
				);
			}
		},
	};
};
