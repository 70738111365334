import { useEffect, useState } from "react"

export const useWaitUsrInteraction = () => {
	const [hasInteract, setHasInteract] = useState<boolean>(false)

	const handler = () => { setHasInteract(true) }

	useEffect(() => {
		if (!hasInteract) {
			window.addEventListener("scroll", handler, { passive: true })
			window.addEventListener("mousemove", handler, { passive: true })
			window.addEventListener("keydown", handler, { passive: true })
			window.addEventListener("click", handler, { passive: true })
			window.addEventListener("touchstart", handler, { passive: true })
		} else {
			window.removeEventListener("scroll", handler)
			window.removeEventListener("mousemove", handler)
			window.removeEventListener("keydown", handler)
			window.removeEventListener("click", handler)
			window.removeEventListener("touchstart", handler)
		}

		return () => {
			window.removeEventListener("scroll", handler)
			window.removeEventListener("mousemove", handler)
			window.removeEventListener("keydown", handler)
			window.removeEventListener("click", handler)
			window.removeEventListener("touchstart", handler)
		}
	}, [hasInteract])

	return { hasInteract }
}