import { useState, useEffect } from "react";

let isAuthModalShowing = false;
let observers = [];

export const setIsAuthModalShowing = (status) => {
  isAuthModalShowing = status;
  observers.forEach(update => update(status));
}

export function useIsAuthModalShowing() {
  const [authModalState, setAuthModalState] = useState(isAuthModalShowing);

  useEffect(() => {
    observers.push(setAuthModalState);
    setAuthModalState(isAuthModalShowing);
    return () => {
      observers = observers.filter(update => update !== setAuthModalState);
    };
  }, [authModalState]);

  return {
    isAuthModalShowing,
    setIsAuthModalShowing
  }
}