
const CONFIG_QUERY_FRAGMENT = `
    configuration {
        country_id
        country_name
        country_capital
        country_capital_id
        country_code
        site_name
        logo
        information_email
        sales_email
        main_domain
        phone
        currency { id name }
        timezone
        IVA
        analytics_id
        google_tag_manager_id
        rtb_id
        onesignal_configuration { app_id }
        facebook_configuration { pixel_id }
        pinterest_id
        instagram_client_id
        socialMediaLinks { slug name url icon }
        header_links { id title link }
    }
`

const CONFIG_QUERY = `query Configs{
	${CONFIG_QUERY_FRAGMENT}
}`

module.exports = { CONFIG_QUERY, CONFIG_QUERY_FRAGMENT }

