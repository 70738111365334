import { useContext } from "react";
import { ThemeContext } from "../Contexts/Theme/context";
import { ThemeModel } from "../Contexts/Theme/model";

export const useTheme = () => {
	const theme: ThemeModel = useContext(ThemeContext);

	return {
		theme,
	};
};
