import React, { ReactNode, createContext, useReducer, useEffect } from "react";

const UserStateContext = createContext<UserState>({ me: null });
const UserDispatchContext = createContext(null);
export type UserState = {
	me: User;
};
export const defaultState: UserState = { me: null };

const reducer = (state: UserState, action): UserState => {
	switch (action.type) {
		case "set":
			return { ...action.payload };
		case "merge":
			return {
				me: {
					...state.me,
					...action.payload,
				},
			};
		case "reset":
			return { ...defaultState };
		default:
			throw new Error("no funciona");
	}
};

const UserProvider = ({
	children,
	initialState = null,
	active = true,
	callback,
}: {
	children: ReactNode;
	initialState: UserState;
	active: boolean;
	callback?: (state: UserState) => void;
}) => {
	const [state, dispatch] = useReducer(reducer, initialState ?? defaultState);

	if (!active) return <>{children}</>;

	useEffect(() => {
		if (callback) {
			callback(state);
		}
	}, [state]);

	return (
		<UserDispatchContext.Provider value={dispatch}>
			<UserStateContext.Provider value={state}>{children}</UserStateContext.Provider>
		</UserDispatchContext.Provider>
	);
};

export { UserStateContext, UserDispatchContext, UserProvider };
