import React from "react";
import { MetaTags } from "../../Components/SEO/MetaTags/web";
import {
	BannerCountry,
	useCountryBanner,
} from "shared-components/Components/Banners/BannerArgentoInvest/web";

import { RTBhouse } from "../../Components/RTBhouse/web";
import { useFacebookPixel } from "../../GlobalHooks/web/FacebookPixel.hook";
// import { useGoogleAnalytics } from "../../GlobalHooks/web/GoogleAnalytics.hook";
import { useGoogleTagManager } from "../../GlobalHooks/web/GoogleTagManager.hook";

import dynamic from "next/dynamic";

const BannerPopUp = dynamic(
	(): any => import("../../Components/Banners/BannerPopUp/web").then(mod => mod.BannerPopUp),
	{ ssr: false }
);

export const GlobalComponents = ({ children }) => {
	useFacebookPixel(true);
	// useGoogleAnalytics(true);
	useGoogleTagManager(true);

	const { bannerToShow } = useCountryBanner();

	return (
		<>
			<MetaTags />
			{children}

			{bannerToShow !== "normal" && <BannerCountry bannerToShow={bannerToShow} />}
			{bannerToShow == "normal" && <BannerPopUp />}

			<RTBhouse />
		</>
	);
}