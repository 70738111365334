import { FragmentDefiner, useReadFragment } from "../../../GlobalHooks/useReadFragment";
import { PropComponentMode } from "../PropertyInterfaces";
import { useState, useEffect } from "react";

export interface TitleProps {
	id: string;
	level?: 1 | 2 | 3 | 4;
	limitLength?: number;
	hideInformation?: boolean;
	mode?: PropComponentMode;
}

export const FRAGMENT_TITLE = new FragmentDefiner(
	"Property",
	`
    title
    project {
        title
    }
`
);

export function useTitle(id: string, mode: PropComponentMode = "auto") {
	const { loading, data, error } = useReadFragment(FRAGMENT_TITLE, id);
	const [title, setTitle] = useState<string>("");

	useEffect(() => {
		if (data) {
			if ((data?.project[0]?.title && mode == "auto") || mode == "project")
				setTitle(data.project[0].title);
			else {
				setTitle(data.title);
			}
		}
	}, [data]);

	return {
		loading,
		title: title,
		error,
	};
}
