import { gql } from "@apollo/client";

export const QUERY_DYNAMIC_POPUPS_IP = gql`
	query GetDynamicIpPopupInfo {
		getDynamicIpPopupInfo {
            ampLink
            desktop
            mobile
            country
		}
	}
`;
