import { useState, useEffect } from "react";
import { gql } from "@apollo/client";
import { useLazyQuery } from "@apollo/client";
import { FragmentDefiner, useReadFragment } from "../../../GlobalHooks/useReadFragment";

const IMAGES_QUERY = gql`
	query images($propertyId: ID!) {
		property(id: $propertyId) {
			images {
				id
				image
			}
			project {
				images {
					id
					image
				}
			}
		}
	}
`;

export const FRAGMENT_IMAGE = new FragmentDefiner(
	"Property",
	`
    img
`
);

export function useLazyImageGallery({ id, mode = "auto" }) {
	const [gallery, setGallery] = useState([]);
	const { loading: loadingFragment, data: dataFragment, error: errorFragment } = useReadFragment(
		FRAGMENT_IMAGE,
		id
	);

	const [getImages, { data, loading, error, called }] = useLazyQuery(IMAGES_QUERY, {
		variables: {
			propertyId: id,
		},
	});

	useEffect(() => {
		if (called) {
			if ((data?.property?.project[0] && mode == "auto") || mode == "project") {
				if (data.property.project[0].images.length > 0) {
					setGallery(
						generateImgsArray(dataFragment?.img, data.property.project[0].images)
					);
				}
			} else {
				if (data?.property?.images.length > 0) {
					setGallery(generateImgsArray(dataFragment?.img, data.property.images));
				}
			}
		} else {
			setGallery(generateImgsArray(dataFragment?.img));
		}
	}, [data]);

	return {
		gallery,
		getImages,
		called,
		loading,
		error,
	};
}

/**
 * Auxiliary functions
 *
 */

const generateImgsArray = (mainImg = "", imgs = []) => {
	if (!!mainImg) mainImg = mainImg.replace("#HASTH", "").trim();
	let addMainImg = !imgs[0]?.image?.includes(mainImg);
	return addMainImg ? [{ image: mainImg }, ...imgs] : [...imgs];
};
