import React, { ReactNode, createContext, useContext, useEffect, useReducer } from "react";

import { ConfigStateContext } from "../Configurations/context";
import { Filters } from "./model";

export const FiltersStateContext = createContext(null);
export const FiltersDispatchContext = createContext(null);

const defaultState: Filters = {
	page: {
		value: 1,
		text: "Pagina 1",
	},
	order: {
		value: 2,
		text: "Popularidad",
	},
};

const reducer = (state: Filters, action): Filters => {
	switch (action.type) {
		case "merge":
			if (!("page" in action.payload)) {
				action.payload.page = { value: 1, text: "Pagina 1" };
			}

			return { ...state, ...action.payload };
		case "set":
			if (!("page" in action.payload)) {
				action.payload.page = { value: 1, text: "Pagina 1" };
			}

			return { ...action.payload };
		case "reset":
			return { ...defaultState };
		default:
			throw new Error("no funciona");
	}
};

export const FiltersContextProvider = ({
	children,
	initialState = null,
	active,
	callback,
}: {
	children: ReactNode;
	initialState: Filters;
	active: boolean;
	callback?: (state) => void;
}) => {
	if (!active) return <>{children}</>;

	const [state, dispatch] = useReducer(reducer, { ...defaultState, ...initialState });
	const { country_code } = useContext(ConfigStateContext);

	useEffect(() => {
		if (!initialState) return;
		let forceTemporalInCEEE =
			country_code == "ce3"
				? { text: "Alquiler Temporal", value: 4 }
				: state.operation_type_id;

		dispatch({
			type: "merge",
			payload: {
				projects: state.projects,
				page: state.page,
				operation_type_id: forceTemporalInCEEE,
			},
		});
	}, [initialState, country_code]);

	useEffect(() => {
		if (typeof callback == "function") callback(state);
	}, [state]);

	return (
		<FiltersDispatchContext.Provider value={dispatch}>
			<FiltersStateContext.Provider value={state}>{children}</FiltersStateContext.Provider>
		</FiltersDispatchContext.Provider>
	);
};

