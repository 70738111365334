import { FRAGMENT_IMAGE } from "../Property/LazyImageGallery/LazyImageGallery.hook";
import { FRAGMENT_LOCATIONTAG } from "../Property/LocationTag/LocationTag.hook";
import { FRAGMENT_PRICETAG } from "../Property/PriceTag/PriceTag.hook";
import { FRAGMENT_TITLE } from "../Property/Title/Title.hook";
import { FragmentDefiner } from "../../GlobalHooks/useReadFragment";
import gql from "graphql-tag";

export const FRAGMENT_PROPERTY_FAVORITES = new FragmentDefiner(
	"Property",
	`
    id
    title
	link
	isExternal
	price{
		amount
		currency{
			id name rate
		}
	}
    property_type {
        id
        name
	}
	project {
		id
		rooms
		bedrooms
		bathrooms
		minM2
		isEspecial
		link
	}
	bedrooms
	rooms
	bathrooms
	guests
	m2
`
).uses(FRAGMENT_LOCATIONTAG, FRAGMENT_IMAGE, FRAGMENT_TITLE);

export const FRAGMENT_PROPERTY_FAVORITES_LIST = new FragmentDefiner(
	"Property",
	`
    id
    title
	link
	isExternal
	price{
		amount
		currency{
			id name rate
		}
	}
    property_type {
        id
        name
	}
	project {
		id
		rooms
		bedrooms
		bathrooms
		minM2
		isEspecial
		link
	}
	bedrooms
	rooms
	bathrooms
	guests
	m2
`
).uses(FRAGMENT_PRICETAG, FRAGMENT_LOCATIONTAG, FRAGMENT_IMAGE, FRAGMENT_TITLE);

export const CURRENT_USER_QUERY = gql`
	query ME_HEADER {
		me {
			__typename
			firstName
			lastName
			name
			id
			history {
				property_id
			}
			email
			individual
			logo
			country_id
			property_count
			phone
			role
			isIris
			notifications(first: 5) {
				data {
					id
					created_at
					text
					url
					new_tab
					seen
					image
				}
			}
			unread_notifications
			favorites {
				id
				property_id
				property_id
				created_at
				property{
					id
					${FRAGMENT_PROPERTY_FAVORITES_LIST.query()}
				}
			}
			is_terms_and_conditions_accepted
			... on Individual {
				searchAlerts(status: 1) {
					id
					filters
					status
				}
			}
		}
	}
`;

export const LOGOUT_MUTATION = gql`
	mutation LOGOUT {
		logout {
			status
			message
			__typename
		}
	}
`;

export const LOGIN_MUTATION = gql`
	mutation Login($email: String!, $pass: String!, $is_terms_and_conditions_accepted: Boolean) {
		login(input: { username: $email, password: $pass, is_terms_and_conditions_accepted: $is_terms_and_conditions_accepted }) {
			access_token
			user_md5
		}
	}
`;

export const REGISTER_MUTATION = gql`
	mutation Register($email: String!, $pass: String, $name: String, $phone: String, $is_terms_and_conditions_accepted: Boolean) {
		registerIndividual(input: { email: $email, password: $pass, name: $name, phone: $phone, is_terms_and_conditions_accepted: $is_terms_and_conditions_accepted }) {
			access_token
			user_md5
		}
	}
`;

export const SOCIAL_LOGIN_MUTATION = gql`
	mutation socialLogin($token: String!, $provider: String!, $email: String) {
		socialLogin(token: $token, provider: $provider, email: $email) {
			access_token
			user_md5
		}
	}
`;

export const USER_EXISTS_QUERY = gql`
	query userExists($email: String!) {
		userExists(email: $email) {
			id
			is_terms_and_conditions_accepted
			masked_phone
			name
		}
	}
`;

export const FORGOT_PASSWORD_MUTATION = gql`
	mutation forgotPassword($email: String!) {
		forgotPassword(input: { email: $email })
	}
`;

export const GOOGLE_ONE_TAP_MUTATION = gql`
	mutation googleOneTapLogin($token: String!) {
		googleOneTapLogin(token: $token) {
			access_token
			refresh_token
			expires_in
			user_md5
		}
	}
`;

export const MY_FAVORITES_QUERY = gql`
	query MY_FAVORITES {
		me {
			__typename
			favorites {
				id
				property_id
				created_at
				property{
					id
					${FRAGMENT_PROPERTY_FAVORITES.query()}
				}
			}
		}
	}
`;

export const UPDATE_USER_MUTATION = gql`
	mutation updateUser($name: String, $phone: String) {
		updateUser(name: $name, phone: $phone) {
			id
		}
	}
`;
export const DELETE_USER_MUTATION = gql`
	mutation deleteUser {
		deleteUser
	}
`;

