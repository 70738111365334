import { useContext } from "react";
import { ConfigStateContext } from "../../Contexts/Configurations/context";
import { FiltersDispatchContext, FiltersStateContext } from "../../Contexts/Filters/context";
import { Filters, FiltersValues } from "../../Contexts/Filters/model";
import {
	isRent,
	isTemporal,
	propertiesTypeInTemporal,
	showBathrooms,
	showBedrooms,
	showDispositions,
	showFacilities,
	showFloors,
	showPropState,
	showRooms,
} from "../../Utils/Functions";

export const getOnlyValues = (a): FiltersValues => {
	if (a == null || !a) return null;

	return Object.keys(a).reduce((acc, o) => {
		if (a[o] == null) {
			//acc[o] = null;
		} else if (Array.isArray(a[o])) {
			if (a[o].length > 0) {
				acc[o] = a[o].map(j => j.value);
			} else {
				//acc[o] = [];
			}
		} else if (a[o].value != null) {
			acc[o] = a[o].value;
		} else if (typeof a[o] == "string" && a[o] != "") {
			acc[o] = a[o];
		}
		return acc;
	}, {});
};

export const sanitizeMapBound = (filters: FiltersValues): FiltersValues => {
	if (filters) {
		filters.map_bounds = filters?.map_bounds?.filter(point => point?.latitude && point?.latitude);
	}
	return filters;
};

const useFilters = () => {
	const filters = useContext(FiltersStateContext);
	const dispatch = useContext(FiltersDispatchContext);
	const { country_code } = useContext(ConfigStateContext);

	const values = sanitizeMapBound(getOnlyValues(filters));

	const filtersHasNewFilters = (newFilters: Filters) => {
		const newFiltersKeys = Object.keys(newFilters);
		const newFiltersMap = new Map<string,string>(Object.entries(newFilters))
		const currentFiltersMap = new Map<string,string>(Object.entries(filters))
		
		if (newFiltersKeys.length === 0) return false;
		for (let [key, value] of currentFiltersMap.entries()) {
			if(newFiltersMap.has(key) && newFiltersMap.get(key) !== value){
				return true //Si el key ya existe y el valor es el mismo es necesario el cambio
			}
		}
		for(let key of newFiltersMap.keys()){
			if(!currentFiltersMap.has(key) || newFiltersMap.get(key) !== null || newFiltersMap.get(key) !== undefined){
				return true //Si el key no existe en los current filters es necesario el cambio
			}
		}
		return false
	};

	const changeFilters = (new_filters: Filters) => {
		if ("currencyID" in new_filters && new_filters?.currencyID?.value === filters?.currencyID?.value) {
			delete new_filters.currencyID
		}
		if ("facilitiesGroup" in new_filters && !("facilitiesGroup" in filters)) {
			if (new_filters.facilitiesGroup.length === 0) {
				delete new_filters.facilitiesGroup
			}
		}
		if ("m2Currency" in new_filters && new_filters?.m2Currency?.value === filters?.m2Currency?.value) {
			delete new_filters.m2Currency
		}
		if ("operation_type_id" in new_filters) {

			if (isTemporal(new_filters.operation_type_id.value)) {
				if (filters.property_type_id) {
					new_filters.property_type_id = filters.property_type_id.filter(e =>
						propertiesTypeInTemporal(e.value)
					);
				}
				new_filters.m2Min = null;
				new_filters.m2Max = null;
				new_filters.m2Type = null;
			}

			if (!isTemporal(new_filters.operation_type_id.value)) {
				new_filters.season = null;
				new_filters.dateFrom = null;
				new_filters.dateTo = null;
				new_filters.guests = null;
			}

			if (!isRent(new_filters.operation_type_id.value)) {
				new_filters.commonExpenses = null;
			}
		}
		if ("property_type_id" in new_filters) {
			let properties = new_filters.property_type_id.map(e => e.value);

			let operation = new_filters.operation_type_id ? new_filters.operation_type_id.value : filters.operation_type_id.value;

			if (!showBedrooms(properties, operation, country_code)) {
				new_filters.bedrooms = null;
			}

			if (!showBathrooms(properties, operation, country_code)) {
				new_filters.bathrooms = null;
			}

			if (!showRooms(properties, operation, country_code)) {
				new_filters.rooms = null;
			}

			if (!showPropState(properties, operation)) {
				new_filters.constStatesID = null;
			}

			if (!showFloors(properties)) {
				new_filters.floors = null;
			}

			if (!showDispositions(properties)) {
				new_filters.dispositionID = null;
			}

			if (!showFacilities(properties, operation)) {
				new_filters.facilitiesGroup = null;
			}
		}
		if ("estate_id" in new_filters) {
			if (new_filters.estate_id == null) {
				new_filters.neighborhood_id = [];
			}
		}

		const newFiltersExist = Object.keys(new_filters).length > 0;

		if (!newFiltersExist) {
			return filters;
		} else if (!filtersHasNewFilters(new_filters)) {
			return null;
		} else {
			return dispatch({
				type: "merge",
				payload: { ...new_filters },
				country_code: country_code,
			});
		}
	};

	return {
		filters: values,
		filtersTags: filters,
		changeFilters,
	};
};

export { useFilters };
