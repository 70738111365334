import {useApolloClient} from "@apollo/client";
import {gql} from "@apollo/client";
import md5 from "js-md5";

var singletonDefinedFragments = (function() {
    let fragments = {};

    function key (id, typename, fields) {
        return md5(typename+";"+id+";"+fields);
    }

    function newFragment(fragmentKey,id, typename, fields) {
        const fragmentID = typename + ":" + id;
        return {
            id: fragmentID,
            fragment: gql`fragment fragment_${fragmentKey} on ${typename} { ${fields } }`
        };

    }

    return {
        generate: function (id,typename,fields) {
            const fragmentKey = key(id,typename,fields);
            if (typeof fragments[fragmentKey] == "undefined") {
                fragments[fragmentKey] = newFragment(fragmentKey,id,typename,fields);
            }
            return fragments[fragmentKey];

        },

    };
})();


export class FragmentDefiner {
    constructor(typename,fields) {
        this.typename = typename;
        this.fields = fields;
        this.fragments = [];
    }

    uses(...fragments) {
        this.fragments = [...this.fragments,...fragments];
        return this;
    }

    read(id,customTypename) {
        const fragment = singletonDefinedFragments.generate(id, customTypename?customTypename:this.typename, this.fields);;
        return fragment;
    }

    query() {
        const f = this.fragments.reduce((acc,o) => {
            return acc + " " + o.query()
        },"");

        return ` ${f} ${this.fields} `
        //return ` ... on ${this.typename} { ${f} ${this.fields} }`
    }


}

export function useReadFragment(fragment, id, customTypename) {

    const data = useApolloClient().readFragment(fragment.read(id, customTypename));

    return { loading: data==null, data, error: null }
}
