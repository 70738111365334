import { useContext, useEffect } from "react";
import { ConfigStateContext } from "../../Contexts/Configurations/context";
import { useUser } from "../../Components/User/User.hook";
import { useRouter } from "next/router";
import { useWaitUsrInteraction } from "./useWaitUsrInteraction";
declare global {
	interface Window {
		dataLayer: {
			[key: string]: any;
			dataLayerName?: string;
		}[];
	}
}
export const useGoogleTagManager = (isInitializer: boolean = false) => {
	const router = useRouter();
	const { google_tag_manager_id } = useContext(ConfigStateContext);
	const { user, isLoggedIn } = useUser();
	const IS_BROWSER = typeof window !== "undefined";
	const userData = isLoggedIn ? { email: user.data?.me.email } : {};
	const { hasInteract } = useWaitUsrInteraction();

	const Init = () => {
		if (!window["GTM_INITIALIZED"]) {
			const script = document.createElement("script");
			script.innerHTML = '(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({\'gtm.start\': new Date().getTime(),event:\'gtm.js\'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!=\'dataLayer\'?\'&l=\'+l:\'\';j.async=true;j.src=\'https://www.googletagmanager.com/gtm.js?id=\'+i+dl+\'&gtm_cookies_win=x\';f.parentNode.insertBefore(j,f);})(window,document,\'script\',\'dataLayer\',\'' + google_tag_manager_id + '\');';
			document.head.insertBefore(script, document.head.childNodes[0]);

			const noscript = document.createElement('noscript');
			noscript.innerHTML = '<iframe src="https://www.googletagmanager.com/ns.html?id=' + google_tag_manager_id + '" height="0" width="0" style="display:none;visibility:hidden"></iframe>';
			document.body.insertBefore(noscript, document.body.childNodes[0]);

			window["GTM_INITIALIZED"] = true;
			console.log(
				"%cInit Google Tag Manager",
				"color:white; background: #33a952; padding:2px 4px"
			);
		}
	};

	const GTMevent = ({ name, data }: { name?: string; data?: object }) => {
		if (window["GTM_INITIALIZED"]) {
			const script = document.createElement('script');
			script.innerHTML = `window.dataLayer = window.dataLayer || [];
			window.dataLayer.push(${JSON.stringify({
				user: userData,
				...data,
				dataLayerName: name || "dataLayer",
			})});`;

			document.head.insertBefore(script, document.head.childNodes[0]);
		}
	};
	const PageView = () => {
		GTMevent({ name: "PageView", data: { page: window.location.href } });
		console.log(
			"%cGoogle Tag Manager PageView",
			"color:white; background: #33a952; padding:2px 4px"
		);
	};

	useEffect(() => {
		if (isInitializer && IS_BROWSER && !window["GTM_INITIALIZED"] && google_tag_manager_id) {
			Init();
			PageView();
			// al cambiar de pagina con shallow routing que mande el evento de PageView
			const customHistory = [window.location.href];
			router.events.on("routeChangeComplete", (e, e2) => {
				if (customHistory[0] != window.location.href) {
					PageView();
					customHistory.unshift(window.location.href);
				}
			});
		}
	}, [google_tag_manager_id]);

	return {
		Event: GTMevent,
	};
};
