const theme = {
	images: {
		logo: "https://cdn2.infocasas.com.uy/web/th.outside135x26.6584a3995c776_logo-(1).png",
		isotipo: "https://cdn2.infocasas.com.uy/web/th.outside50x50.6585ca4d074cd_fincaraiz-iso-mobile.png",
		favicon: "https://cdn1.infocasas.com.uy/web/658477f665b20_icon-(1).ico",
		icono: "https://cdn2.infocasas.com.uy/web/th.outside50x50.6585ca4d074cd_fincaraiz-iso-mobile.png",
	},
	fontFamily: "Fincaraiz, system, -apple-system, Roboto, Segoe UI Bold, Arial, sans-serif",
	colors: {
		primaryColor: "#F9DA88",
		primaryDarkColor: "#FBD264",
		primaryHoverColor: "rgba(249, 218, 136, 0.7)",
		secondaryColor: "#077BE7",
		secondaryDarkColor: "#F7CC5C",
		secondaryHoverColor: "#F9DA88",
		secondaryOpacityColor: "rgba(7, 123, 231, 0.5)",
		textColorHigh: "#082645",
		textColorMedium: "#456787",
		textColorLow: "#8F9DA8",
		textSecondaryColor: "#808080",
		textOnSecondaryColor: "#fff",
		textInverseColor: "#a7a7a7",
		textHoverColor: "#082645",
		textLinkSecondary: "#0672D7",
		backgroundColor: "#fff",
		backgroundMainColor: "#077BE7",
		backgroundModalColor: "rgba(0, 0, 0, 0.5)",
		backgroundMenuColor: "#FFFFFF",
		textMenuColor: "#456787",
		linkColor: "#077BE7",
		linkHoverColor: "#077BE7",
		backgroundColorAternative: "#F2F6FD",
		borderColor: "#E9E9E9",
		favoriteColor: "#ff6767",
		successColor: "#69AD6C",
		errorColor: "#F24E1E",
		warningColor: "#fff3cd",
		placeholderTextColor: "#cccccc",
		contentButtonColor: "#456787",
	},
	breakPoints: {
		xs: "576px",
		sm: "576px",
		md: "768px",
		lg: "992px",
		xl: "1200px",
		xxl: "1600px",
	},
	headerHeight: 64,
	spacing: {
		xsSpacing: 4,
		smSpacing: 8,
		mdSpacing: 12,
		lgSpacing: 16,
		xlSpacing: 24,
		xxlSpacing: 32,
	},
	fontSizes: {
		baseFontSize: "16px",
		baseLineHeight: 1.5,

		lgFontSize: "16px",
		smFontSize: "13px",
		xsFontSize: "11px",

		xlTitle: "32px",
		xlLineHeight: "40px",
		lgTitle: "28px",
		lgLineHeight: "36px",
		mdTitle: "24px",
		mdLineHeight: "32px",
		smTitle: "20px",
		smLineHeight: "28px",
		xsTitle: "18px",
		xsLineHeight: "24px",
	},
};

module.exports = theme;

