import {FragmentDefiner, useReadFragment} from "../../../GlobalHooks/useReadFragment";
import { useEffect, useState } from "react";

export const FRAGMENT_LOCATIONTAG = new FragmentDefiner("Property",`
    neighborhood {
        name
    }
    estate {
        name
    }
    address
    showAddress
    project {
        neighborhood { name }
        estate { name }
        address
    }
`);


export function useLocationTag({id, mode = "auto"}) {
    const [address, setAddress] = useState("");
    const [neighborhood, setNeighborhood] = useState("");
    const [estate, setEstate] = useState("");
    const [showAddress, setShowAddress] = useState(true);

    const {loading, data, error} = useReadFragment(FRAGMENT_LOCATIONTAG,id);

    useEffect(() => {
        if (data) {
            setShowAddress(data.showAddress);
            if((data.project[0] && mode == "auto") || mode == "project"){
                setAddress(data.project[0].address);
                setNeighborhood(data.project[0].neighborhood ? data.project[0].neighborhood.name : "");
                setEstate(data.project[0].estate ? data.project[0].estate.name : "");
            } else {
                setAddress(data.address);
                setNeighborhood(data.neighborhood ? data.neighborhood.name : "");
                setEstate(data.estate ? data.estate.name : "");
            }
        }
    }, [data]);

    return { 
        loading, 
        address,
        neighborhood,
        showAddress,
        estate, 
        error 
    }
}
