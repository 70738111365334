import React, { ReactNode, createContext, useEffect, useReducer } from "react";

import { Configs } from "./model";
import { gql, useQuery } from "@apollo/client";
import { CONFIG_QUERY } from "./ConfigsQuery";
import { thereIsTranslations } from "src/utils/Functions";
import { translations } from 'src/utils/Translations';
import getConfig from 'next/config'

const ConfigStateContext = createContext<Configs>({});
const ConfigDispatchContext = createContext(null);
const { mainDomain, siteName } = getConfig().publicRuntimeConfig;

export const defaultState: Configs = {
	country_id: null,
	main_domain: mainDomain,
	site_name: siteName,
	translations: translations["DEFAULT"],
	isDefault: true,
};

const reducer = (state: Configs, action): Configs => {
	switch (action.type) {
		case "set":
			return { ...action.payload };
		case "merge":
			return { ...state, ...action.payload };
		case "reset":
			return { ...defaultState };
		default:
			throw new Error("no funciona");
	}
};


const ConfigurationsProvider = ({
	children,
	initialState = null,
	active,
}: {
	children: ReactNode;
	initialState: Configs;
	active: boolean;
}) => {
	const [state, dispatch] = useReducer(reducer, initialState ? { ...initialState, translations: translations[initialState.country_code] } : defaultState);
	const { data } = useQuery(gql`${CONFIG_QUERY}`, {
		skip: !!initialState || state && state.country_id,
		ssr: false,
	})

	useEffect(() => {
		if (data?.configuration) {
			const code = thereIsTranslations(data?.configuration.country_code) ? data?.configuration.country_code : "UY"
			const translation: any = translations[code];

			dispatch({ type: "set", payload: { ...data.configuration, translations: translation, isDefault: false } })
		}
	}, [data])

	if (!active) return <>{children}</>;

	return (
		<ConfigDispatchContext.Provider value={dispatch}>
			<ConfigStateContext.Provider value={state}>{children}</ConfigStateContext.Provider>
		</ConfigDispatchContext.Provider>
	);
};

export { ConfigStateContext, ConfigDispatchContext, ConfigurationsProvider };
