import "./styles.less";
import React, { useEffect, useState } from "react"
import dynamic from "next/dynamic"
const Modal = dynamic(() => import("shared-components/Components/Modal/web").then(mod => mod.Modal), { ssr: false });
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import { QUERY_DYNAMIC_POPUPS_IP } from "../../../GQL/DynamicIpPopups";
import { useQuery } from "@apollo/client";

interface popupImage {
	ampLink: string,
	desktop: string,
	mobile: string,
}

export const BannerCountry = ({ bannerToShow }) => {
	const [isOpen, setOpen] = useState(true);
	const [imgs, setImgs] = useState({} as popupImage);
	const onClose = () => setOpen(false);
	const { md } = useBreakpoint();
	const { data: popups } = useQuery(QUERY_DYNAMIC_POPUPS_IP, {});

	useEffect(() => {
		if (popups && !!popups.getDynamicIpPopupInfo.length && bannerToShow !== null) {
			const filterPopup = popups.getDynamicIpPopupInfo.filter((p) => p.country === bannerToShow)[Math.floor(Math.random() * popups.getDynamicIpPopupInfo.length)];
			setImgs(filterPopup);
		}
	}, [bannerToShow])

	if (!imgs || !Object.keys(imgs).length) return null;

	return (
		<>
			<Modal width="740px" classes="modal-transparent" background="transparent" onClose={onClose} show={isOpen}>
				<div className="modal-popup banner_argento_invest">
					<img
						alt="Evento"
						src={md ? imgs.desktop : imgs.mobile}
						onClick={() => window.open(imgs.ampLink)}
						style={{ cursor: "pointer" }}
					/>
				</div>
			</Modal>
		</>
	);
};

export const useCountryBanner = () => {
	const [bannerToShow, setBannerToShow] = useState<"AR" | "CL" | "normal">(null);

	useEffect(() => {
		//@ts-ignore
		showCountryBanner().then(({ showAR, showCL }) => {
			setBannerToShow(showAR ? "AR" : showCL ? "CL" : "normal");
		});
	}, []);

	return { bannerToShow };
};

/**
 * Aux functions
 */
const validateCountry = async (localStorageName, country) => {
	if (typeof window == "undefined" || !localStorage) return false;

	const is_from_country = localStorage?.getItem(localStorageName);
	let hasCheckedCountry = is_from_country !== null;
	const apikey = "dacaa865c0f5775175e230d033016139";
	const apiUrl = `https://api.ipstack.com/check?access_key=${apikey}`;

	let isCountry = false;

	if (!hasCheckedCountry) {
		try {
			let res = await fetch(apiUrl).then(res => res.json()).catch(e => { console.error('error in validateCountry(): ', e) });

			let countryCode = res?.country_code ?? "UY";
			isCountry = countryCode == country;
			localStorage.setItem(localStorageName, isCountry ? "true" : "false");
		} catch (error) {
			console.warn('validateCountry error: ', error)
		}
	}

	return isCountry;
}

export const ipIsFromCountry = async (countryCode) => {
	const apikey = "dacaa865c0f5775175e230d033016139";
	const apiUrl = `https://api.ipstack.com/check?access_key=${apikey}`;
	let res = await fetch(apiUrl).then(res => res.json()).catch(e => { console.error('error in validateCountry(): ', e) });

	return (res?.country_code ?? "UY") === countryCode
}

const showCountryBanner = async () => {
	if (typeof window == "undefined") return false;

	const showAR = await showCountry("is_from_ar", "ar_invest_banner", "AR");
	const showCL = await showCountry("is_from_cl", "cl_invest_banner", "CL");

	return { showAR, showCL };
};

const showCountry = async (localStorageName, localStorageNameShow, country) => {
	try {
		const lastTime = localStorage?.getItem(localStorageName);
		const timePassed = Date.now() - parseInt(lastTime) > 1000 * 60 * 60 * 24;
		const isCountry = await validateCountry(localStorageName, country);
		let showCountry = false;
		if ((!lastTime || timePassed) && isCountry) {
			let date = Date.now();
			localStorage.setItem(localStorageNameShow, date.toString());
			showCountry = true;
		}
		return showCountry;
	} catch (error) {
		console.error("Error accessing localStorage:", error);
		return false;
	}
}
