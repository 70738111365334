import { useContext } from "react";
import { FragmentDefiner } from "../../../GlobalHooks/useReadFragment";
import { formatMoney, isTemporal, makePriceText, shortNumber } from "../../../Utils/Functions";

import { PropComponentMode } from "../PropertyInterfaces";
import { ConfigStateContext } from "shared-components/Contexts/Configurations/context";

export const FRAGMENT_PRICETAG = new FragmentDefiner(
	"Property",
	`
	id
    price_variation {
      	difference
      	percentage
      	currency {
			id name rate
      	}
      	date
      	amount
    }
    price {
		currency {
			id name rate
		}
		amount
		hidePrice
    }
    commonExpenses{
		amount
		currency {
			id name rate
		}
		hidePrice
    }
    project {
        id
		minPrice {
			currency {
            	id name rate
            }
			amount
			hidePrice
    	}
    }
    operation_type {
		id
        name
	}
	temporal_price
    temporal_currency{ id name rate }
`
);

type usePriceTagProps = {
	threeDigitsFormat?: boolean;
	showFromText?: boolean;
	mode: PropComponentMode;
	shortExpensesText: boolean;
	price: Price;
	maxPrice?: Price;
	priceVariation?: PriceVaritation;
	commonExpenses?: CommonExpenses;
	temporalPrice?: number;
	operationType?: OperationType;
	temporalCurrency?: Currency;
	isProject?: boolean;
	isApp?: boolean;
};

export function usePriceTag({
	threeDigitsFormat = false,
	showFromText = true,
	shortExpensesText = true,
	price,
	maxPrice,
	priceVariation,
	commonExpenses,
	temporalPrice,
	operationType,
	temporalCurrency,
	isProject = false,
	mode = "auto",
	isApp = false,
}: usePriceTagProps) {
	let newPrice: any = price;
	let priceText: string = "Consultar";
	let maxPriceText: string = "";
	let price_variation: any = null;
	let common_expenses: any = { hide: true, text: "" };
	let operation_type: string = operationType?.name;
	let isTemp: boolean = isTemporal(operationType?.id);
	const forceProperty: boolean = mode === "property";
	const { country_code } = useContext(ConfigStateContext);

	if (price && !price?.hidePrice && !isTemp) {
		const shortPrice = `${price?.currency?.name}${shortNumber(price.amount)}`;
		const largePrice = makePriceText(price, isApp);

		if (isProject && showFromText && !forceProperty) {
			const projectPrice = threeDigitsFormat ? shortPrice : largePrice;
			priceText = `${threeDigitsFormat ? "+" : "Desde"} ${projectPrice}`;

			if (maxPrice && maxPrice.amount !== price.amount) {
				maxPriceText = `Hasta ${makePriceText(maxPrice, isApp)}`;
			}
		} else {
			priceText = threeDigitsFormat ? shortPrice : largePrice;
		}
	}
	if (priceVariation && priceVariation?.percentage < 50 && !isTemp) {
		price_variation = {
			text: makePriceText(priceVariation, isApp),
			...priceVariation,
		};
	}

	var currency = country_code === "CO" ? { name: "$", id: "3" } : {};
	if (commonExpenses && (!isProject || forceProperty) && !isTemp) {
		const complementText = shortExpensesText ? "gc" : "gastos comunes"
		const fincaComplementText = shortExpensesText ? "admin" : "administración"
		const commonExpensesText = `+ ${makePriceText({ ...commonExpenses, currency: currency }, isApp)} ${country_code === "CO" ? fincaComplementText : complementText}`;
		common_expenses = { hide: commonExpenses.hide, text: commonExpensesText };
	}
	if (isTemp) {
		newPrice = {
			amount: temporalPrice,
			isTemp,
			currency: temporalCurrency ? temporalCurrency : price.currency,
		};
		priceText = `${temporalCurrency ? temporalCurrency?.name : price?.currency?.name} ${formatMoney(Math.round(temporalPrice))}`;
	}

	return {
		data: {
			price: {
				...newPrice,
				text: priceText,
				maxPriceText: maxPriceText
			},
			commonExpenses: common_expenses,
			operationType: {
				text: operation_type,
			},
			price_variation: price_variation,
		},
	};
}
