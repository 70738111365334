import { useContext } from "react";
import { ConfigStateContext } from "../../Contexts/Configurations/context";

// Info: https://cdn2.infocasas.com.uy/web/5f807e0651675_infocdn__rtbhouse_iframe_codes.pdf
export const RTBhouse = ({
	page,
	data,
}: {
	page?: "home" | "offer" | "listing" | "ficha" ;
	data?: string | string[]; 
}) => {
	const { rtb_id } = useContext(ConfigStateContext);

	const getPage = () => (page && page != 'ficha' ? "_" + page : "");
	const getData = () => {
		if (page == "offer") return "_" + data;
		else if (page == "listing" && typeof data != "string") return "_" + data.join(",");
		else if(page == "ficha") return `_orderstatus2_1_${data[0]}_${data[1]}&amp;cd=defaul`
		else if (typeof page == "undefined") return "&amp;ncm=1";
		else return "";
	};

	if (!rtb_id) return null;
	return (<>
		<iframe
			src={`https://us.creativecdn.com/tags?id=${rtb_id}${getPage()}${getData()}`}
			width="1"
			height="1"
			scrolling="no"
			frameBorder="0"
			style={{ display: "none !important" }}></iframe>
	</>);
};
