const InfoCasasBrand = require("./InfoCasasBrand");
const CasasEnElEsteBrand = require("./CasasEnElEsteBrand");
const FincaraizBrand = require("./FincaraizBrand");

const getBranding = appTheme => {
	switch (appTheme) {
		case "casaseneleste":
			return CasasEnElEsteBrand;
		case "infocasas":
			return InfoCasasBrand;
		case "fincaraiz":
			return FincaraizBrand;
		default:
			return InfoCasasBrand;
	}
};

module.exports = { getBranding };

