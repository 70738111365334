import { ReactNode, createContext } from "react";

import React from "react";
import { ThemeModel } from "./model";
import { getBranding } from "shared-components/Styles/JSthemes/getBranding";

const ThemeContext = createContext(null);

const ThemeProvider = ({
	children,
	initialState = null,
	APP_THEME = "infocasas", // APP_THEME now is passed as a prop
	active,
}: {
	children: ReactNode;
	initialState?: ThemeModel;
	active: boolean;
	APP_THEME?: "infocasas" | "casaseneleste" | "fincaraiz";
}) => {
	if (!active) return <React.Fragment>{children}</React.Fragment>;
	const branding = initialState ? initialState : getBranding(APP_THEME);

	return <ThemeContext.Provider value={branding}>{children}</ThemeContext.Provider>;
};

export { ThemeContext, ThemeProvider };
