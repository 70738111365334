import { decode as atob, encode as btoa } from "base-64";

export const UY = 1;
export const PY = 2;
export const CO = 3;
export const PE = 4;
export const BO = 5;
export const CEEE = 37;

export const parseMoney = (
	value: any,
	maximumFractionDigits = 0,
	nonSignificantDigits = 0,
	locale: "it-IT" | "en" = "it-IT"
) => {
	const float = parseFloat(value);
	const digits = Math.round(float).toString().length;
	const maximumSignificantDigits =
		digits > nonSignificantDigits ? digits - nonSignificantDigits : digits;
	return float.toLocaleString(locale, { maximumFractionDigits, maximumSignificantDigits });
};
export const formatMoney = (value: any) => {
	value = value ? value : "";
	value = isNaN(value) ? value : value.toString();
	value = value.replace(/[^\d]/g, "");
	value = value.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
	return value;
};
export const parserMoney = (value: any) => {
	value = isNaN(value) ? value : value.toString();
	value = value.replace(/[^\d]/g, "");
	return Number(value);
};
export const isSuperHighlighted = (highlight: number, country_code?: string) => {
	return [1, 2, 3, 10, 11].includes(highlight);
};
export const isHighlighted = (highlight: number) => {
	return !isSuperHighlighted(highlight) && ![0, 8, 13].includes(highlight);
};

export const isHighlightedWithTag = (highlight: number, country_code?: string) => {
	return isHighlighted(highlight) &&
		(country_code === "CO" &&
			(highlight !== 14 && highlight !== 22 && highlight !== 21) ||
			country_code !== "CO"
		)
}

export const isTransactional = (highlight: number) => [1, 11, 12, 15].includes(highlight);

export const isAuction = (operation_type_id: number) => {
	return operation_type_id == 6;
};
export const isTemporal = (operation_type_id?: number | string) => {
	return operation_type_id == 4;
};

export const isSell = (operation_type_id: number | string) => {
	return operation_type_id == 1;
};

export const isRent = (operation_type_id: number | string) => {
	return operation_type_id == 2;
};

export const isCommerce = (property_type: number) => {
	return property_type == 4;
};

export const showGuests = (operation_type_id?: number) => {
	return isTemporal(operation_type_id);
};

export const showBedrooms = (
	property_type_id: any = [],
	operation_type_id: number,
	country_code: string = "UY"
) => {
	if (typeof property_type_id === "number") return [];
	return (
		([1, 2].some(e => property_type_id.includes(e)) && !isAuction(operation_type_id)) ||
		country_code === "BR" ||
		(Array.isArray(property_type_id) && property_type_id.length === 0) ||
		(country_code === "CO" && ![4, 5, 9].some(e => property_type_id.includes(e)))
	);
};

export const showBathrooms = (
	property_type_id: any = [],
	operation_type_id: number,
	country_code: string = "UY"
) => {
	if (typeof property_type_id === "number") return [];
	return (
		property_type_id.length == 0 ||
		([1, 2, 4, 5, 9].some(e => property_type_id.includes(e)) && !isAuction(operation_type_id)) ||
		country_code === "CO"
	);
};

export const showRooms = (
	property_type_id: any = [],
	operation_type_id: number,
	country_code: string = "UY"
) => {
	return (
		!showBedrooms(property_type_id, operation_type_id, country_code) &&
		!property_type_id.includes(3) &&
		(country_code === "CO" && [4, 5, 9].some(e => property_type_id.includes(e)))
	);
};

export const hasRooms = (property_type_id) => {
	return [4, 5, 9].includes(property_type_id)
}

export const showM2 = (operation_type_id: number) => {
	return !isTemporal(operation_type_id);
};

export const showDateRange = (operation_type_id: number) => {
	return isTemporal(operation_type_id);
};
export const showSeason = (operation_type_id: number, country_id: number) => {
	return isTemporal(operation_type_id) && [UY, CEEE].includes(country_id);
};
export const showTemporalChanger = (country_id: number) => {
	return [UY, CEEE].includes(country_id);
};

export const propertiesTypeInTemporal = (property_type_id: number) => {
	return property_type_id == 1 || property_type_id == 2 || property_type_id == 6;
};

export const showPropState = (property_type_id: any = [], operation_type_id: number) => {
	if (property_type_id && property_type_id.length == 1 && property_type_id[0] == 3) return false;
	return !isTemporal(operation_type_id);
};

export const showPropertyType = (operation_type_id: number) => {
	return !isTemporal(operation_type_id);
};

export const showLocation = (operation_type_id: number) => {
	return !isAuction(operation_type_id);
};

export const showCommonExpenses = (operation_type_id: number) => {
	return operation_type_id == 2;
};
export const showPrice = (operation_type_id: number) => {
	return !isAuction(operation_type_id);
};

export const showFloors = (property_type_id: any = []) => {
	return [2].some(e => property_type_id.includes(e));
};

export const showDispositions = (property_type_id: number[] = []) => {
	return [2].some(e => property_type_id.includes(e));
};

export const showFacilities = (property_type_id: any = [], operation_type_id: number) => {
	return (
		property_type_id.length == 0 ||
		([1, 2, 4, 5, 6, 10, 11, 13].some(e => property_type_id.includes(e)) &&
			!isAuction(operation_type_id))
	);
};

export const showSocialHousing = (property_type_id: any = [], operation_type_id: number) => {
	return (
		(operation_type_id == 1 && property_type_id.length == 0) ||
		[1, 2, 3, 5, 6, 12, 13].some(e => property_type_id.includes(e))
	);
};

export const showPrivateOwner = (operation_type_id: number) => {
	return !isAuction(operation_type_id);
};

export const showSeaDistance = (operation_type_id: number, country_code: string) => {
	return (
		!isAuction(operation_type_id) && country_code && ["UY", "PE", "ce3"].includes(country_code)
	);
};

export const hideInformationFromText = (text: string, mask: string = "***") => {
	let r = text;
	const pattern_email = "(\\S*@\\S*?\\.\\S+)";
	let re = new RegExp(pattern_email, "gi");
	r = r.replace(re, mask);

	const pattern_url =
		"\\b((?:https?:|www\\d{0,3}[.]|[a-z0-9.\\-]+[.][a-z]{2,4})(?:[^\\s()<>]+|\\(([^\\s()<>]+|(\\([^\\s()<>]+\\)))*\\))+(?:\\(([^\\s()<>]+|(\\([^\\s()<>]+\\)))*\\)|[^\\s`\\!()\\[\\]{};:'\".,<>?«»“”‘’]))";
	re = new RegExp(pattern_url, "gi");
	r = r.replace(re, mask);

	const pattern_phone = "((\\+\\s?)?(\\d[\\s|\\.]?){7,16})";
	re = new RegExp(pattern_phone, "gi");
	r = r.replace(re, mask);

	return r;
};
export const encodeHashUrl = (input: object) => {
	let hash = btoa(unescape(encodeURIComponent(JSON.stringify(input))));

	return hash;
};
export const decodeHashUrl = (input: string) => {
	let unHash = JSON.parse(decodeURIComponent(escape(atob(input))));

	return unHash;
};

export const capitalize = (name: string): string => name.charAt(0).toUpperCase() + name.slice(1);

export const removeUrlProtocol = (url: string): string => {
	return url?.replace(/(^\w+:|^)\/\//, "//");
};

export const removeFirstBar = (url: string): string => {
	return url.slice(1, url.length);
};

export const shortNumber = (number: number) => {
	const oneMillion = 1000000;
	const oneThousand = 1000;

	if (number >= oneMillion) {
		return `${Math.round(number / oneMillion)}M`;
	} else if (number >= oneThousand) {
		return `${Math.round(number / oneThousand)}K`;
	}

	return number;
};

export const makePriceText = (price: any, isApp: boolean = false): string => {
	return `${price.currency ? price.currency.name + " " : ""}${isApp ? formatMoney(price.amount) : parseMoney(price.amount)
		}`;
}

export const cleanHtmlFromString = (html: string, keepLineBreaks: boolean = false) => {
	if (html) {
		if (keepLineBreaks) html = html.replace(/<br>/g, "$br$");
		html = html.replace(/<\/?[^>]+(>|$)/g, "");
		html = html.replace(/\$br\$/g, "<br><br>");
		html = html
			.replace(/\&gt;/g, ">")
			.replace(/\&lt;/g, "<")
			.replace(/\&nbsp;/g, "\u00A0");
	}
	return html;
};

export const cleanJumpsHtmlFromString = (html: string) => {
	if (html) {
		html = html.replace(/<p><br[\/]?><[\/]?p>/g, "");
		html = html.replace(/[\/]?h[1-6]>/g, "p>");
	}

	return html;
};

export const postRequest = async (
	url,
	data,
	headers: HeadersInit = {
		"Content-Type": "application/json",
	}
) => {
	try {
		const response = await fetch(url, {
			method: "POST",
			headers: headers,
			body: JSON.stringify(data),
		});
		return response.json();
	} catch {
		return "error";
	}
};

export const replaceBr = str => {
	if (!str) {
		return "";
	}
	const regex = /<br\s*[\/]?>/gi;
	return str.replace(regex, "\n");
};

export const filterMarkers = markers => {
	const arrayMarkers = [...markers];

	var lats = new Array();
	var longs = new Array();

	for (let i = 0; i < markers.length; i++) {
		lats[i] = markers[i].latitude;
		longs[i] = markers[i].longitude;
	}

	//media de coordenadas (centro)
	lats.sort();
	longs.sort();

	var media = Math.round(markers.length / 2);
	var center = [lats[media], longs[media]];

	//hallo distancias de todos los puntos al centro y las guardo en un array
	var distancia;
	var distancias = [];

	for (let i = 0; i < markers.length; i++) {
		//distancia entre 2 puntos
		distancia = Math.sqrt(
			Math.pow(center[0] - markers[i].latitude, 2) +
			Math.pow(center[1] - markers[i].longitude, 2)
		);
		distancias[i] = distancia;
	}
	//media de distancias al centro
	var distanciasAux = distancias.slice(0); //guardo en un aux para que quede en el mismo orden que markers
	distancias.sort();
	var mediaDistancias = distancias[media];
	//excluyo todos los markers cuya distancia sea 3 veces mayor a la media de distancias
	// console.log(distanciasAux);
	for (let i = distanciasAux.length - 1; i >= 0; i--) {
		// console.log(distanciasAux[i] > mediaDistancias * 0.8);
		if (distanciasAux[i] > mediaDistancias * 1.2) {
			// console.log(i);
			arrayMarkers.splice(i, 1);
		}
	}
	// console.log(arrayMarkers);
	// markers.splice(99, 1);
	return arrayMarkers;
};

export const isValidCharacters = (str: string): boolean => {
	const regex = /^[a-zA-ZÀ-ÿ\u00f1\u00d10-9][a-zA-ZÀ-ÿ\u00f1\u00d10-9\s]*$/;
	return regex.test(str);
};

export const isValidCharactersXSS = (str: string): boolean => {
	const regex = /^[^*|\":<>[\]{}`\\()';@&$]+$/;
	return regex.test(str);
};

export const isValidEmail = (str: string): boolean => {
	const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
	return emailRegex.test(str);
};

export const isValidPhone = (str: string): boolean => {
	const regex = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-s./0-9]*$/;
	return regex.test(str);
};

export const getParameterByName = (url, name) => {
	name = name.replace(/[\[\]]/g, "\\$&");
	var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
		results = regex.exec(url);
	if (!results) return null;
	if (!results[2]) return "";
	return decodeURIComponent(results[2].replace(/\+/g, " "));
};

export const simplifyString = (s: string) => {
	let r = s.toLowerCase();
	r = r.replace(new RegExp("\\s", "g"), "");
	r = r.replace(new RegExp("[àáâãäå]", "g"), "a");
	r = r.replace(new RegExp("æ", "g"), "ae");
	r = r.replace(new RegExp("ç", "g"), "c");
	r = r.replace(new RegExp("[èéêë]", "g"), "e");
	r = r.replace(new RegExp("[ìíîï]", "g"), "i");
	r = r.replace(new RegExp("ñ", "g"), "n");
	r = r.replace(new RegExp("[òóôõö]", "g"), "o");
	r = r.replace(new RegExp("œ", "g"), "oe");
	r = r.replace(new RegExp("[ùúûü]", "g"), "u");
	r = r.replace(new RegExp("[ýÿ]", "g"), "y");
	r = r.replace(new RegExp("\\W", "g"), "");
	return r;
};

export const getFilterName = filterCode => {
	switch (filterCode) {
		case "operation_type_id":
			return "Tipo de oferta";
			break;

		case "property_type_id":
			return "Tipo de propiedad";
			break;

		case "bedrooms":
			return "Habitaciones";
			break;

		case "bathrooms":
			return "Baños";
			break;

		case "commonExpenses":
			return "Gastos Comunes";
			break;

		case "floors":
			return "Pisos";
			break;

		default:
			"bedrooms";
			break;
	}
};

export const normalizeTextToLowerCase = value => value.replace(/\s/g, "").toLowerCase();

export const validateAppAndRedirect = (deeplink: string, openlink: string) => {
	// Verifica si la aplicación está instalada en el dispositivo
	if (window.navigator.userAgent.match(/(iPhone|iPod|iPad|Android|Windows Phone)/)) {
		// Intenta abrir la aplicación con el deeplink especificado
		window.location = deeplink as Location | (string & Location);

		setTimeout(function () {
			if (document.visibilityState !== "visible") {
			} else {
				window.location = openlink as Location | (string & Location);
			}
		}, 1000);
	}
};

export const ocultarBigDataFicha_aPedidoHardcodeado = id =>
	["190233073", "190374673", "190645051"].includes(id);

export const removeAccents = (str: string) => {
	return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}

export const isProject = (property) => {
	if (!property) return false
	return property.commercial_units?.length > 0
}

export const getMode = (isProject) => {
	return typeof isProject === "undefined"
		? "auto"
		: isProject ? "project" : "property"
}